.dmkhoaphong .textend {
    display: flex;
    margin-bottom: 5px;
}

.dmkhoaphong .dskhoa {
    margin-right: 10px;
}

.dmkhoaphong .text-secondary {
    font-size: 14px;
    font-weight: 700;
}

.tablekhoaphong .datagrid-body-inner {
    display: inline-block;
}
.DetailKhoaPhong label .labelname{
    font-size: 12px !important;
    margin-top: 4px;
    width: 110px;
    text-align: right;
}
.DetailKhoaPhong  .ant-select-selector {
    margin: 0px 0px !important;
    height: 28px !important;
}
.DetailKhoaPhong .ant-select-arrow {
    top: 50% !important;
}
.DetailKhoaPhong .ant-select-selection-placeholder{
    font-size: 12px !important;
    text-align: left !important;
}
.DetailKhoaPhong .ant-select-selection-item{
    font-size: 12px !important;
    text-align: left !important;
}
.DetailKhoaPhong  .ant-input-number .ant-input-number-input {
    padding: 5px 11px !important;
}
.DetailKhoaPhong  .ant-form-item .ant-form-item-label >label::after {
    content: "";
}
.DetailKhoaPhong .ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
}