.react-combo-boxes-combobox {
    width: max-content;
    position: relative;
    max-width: 100%;
}
.react-combo-boxes-combobox__listbox {
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    box-sizing: border-box;
    overscroll-behavior: none;
    -ms-scroll-chaining: none;
    background-color: #fff;
    border: 2px solid #000;
    width: max-content;
    min-width: 100%;
    max-width: 100%;
    min-height: 1.5em;
    max-height: 30rem;
    margin: 0 1em 1em 0;
    padding: 0;
    list-style: none;
    position: absolute;
    inset: auto;
    overflow-y: auto;
    box-shadow: 0 2px #ffd250, 2px 0 #ffd250, -2px 0 #ffd250, 0 2px 4px #aaa;
}
.react-combo-boxes-combobox__down-arrow {
    vertical-align: middle;
    pointer-events: none;
    background: url(../assets/image/combox.svg) no-repeat;
    width: 1.7em;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}
.react-combo-boxes-combobox__option:hover, .react-combo-boxes-combobox__option:active, .react-combo-boxes-combobox__table-row:hover, .react-combo-boxes-combobox__table-row:active {
    color: #000;
    background-color: #caeaff;
}
.react-combo-boxes-combobox__option, .react-combo-boxes-combobox__table-row {
    cursor: pointer;
    padding: .1em .5em;
}
.react-combo-boxes-combobox__table-cell {
    vertical-align: top;
    border: none;
    padding: .1em 1em .1em .5em;
}
@keyframes react-combo-boxes-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}
