.DMThuocThauBHYT .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.DMThuocThauBHYT .ant-select-arrow {
    top: 50% !important;
}
.DMThuocThauBHYT .ant-select-single .ant-select-selector .ant-select-selection-item, .DMThuocThauBHYT .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}
.DMThuocThauBHYT .lablename-input{
    width: 110px;
}
.DMThuocThauBHYT .lablename-input.maKQDT{
    width: 310px;
}
.DMThuocThauBHYT .STTThuoc{
    width: 200px;
}
.thuoc-thaubhyt .nhomin-act-check{
    display: flex;
}
.DMThuocThauBHYT .ant-input-number .ant-input-number-input {
    padding: 3px 11px 0px 11px !important;
}