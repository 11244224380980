.main-vltl{
    display: inline-block;
    width: 100%;
}
.main-vltl .main-vltl-left{
    float: left;
    width: 30%;
    border-right: 2px groove;
    height: 58vw;
    
}
.main-vltl .main-vltl-right{
    float: left;
    width: 70%;
    background: #fafeff;
}
.main-vltl .main-vltl-left .title{
    font-weight: bold;
    background: #d1d1d1;
    padding: 5px;

}
.main-vltl .main-vltl-right .title{
    font-weight: bold;
    background: #d1d1d1;
    padding: 5px;

}
.main-vltl .main-vltl-left .ant-radio-checked .ant-radio-inner{
    width: 16px !important;
    height: 16px !important
}

.main-vltl .main-vltl-left .vltl-left-ngay{
    display: flex;
    margin-top: 5px;
}
.main-vltl .main-vltl-left .on-vltl-left{
    float: left;
    width: 80%;
    padding: 10px;
}
.main-vltl .main-vltl-left .btn-vltl-left{
    float: left;
    width: 20%;
    text-align: center;
}
.main-vltl .main-vltl-left .btn-vltl-left .btnonsearch{
    width: 40px;
    height: 40px;
    background: #e8e8e8;
    margin-top: 20px;
    border: 1px solid #ccc;
}
.main-vltl .main-vltl-left .btn-vltl-left .btnonsearch .svg-inline--fa {
    height: 2em;
    margin-top: 5px;
}
.main-vltl .main-vltl-left  .vltl-on-btn{
    display: inline-block;
    width: 100%;
}
.main-vltl .main-vltl-left .tabledm{
    padding:5px;
    font-size: 12px;
}
.main-vltl .main-vltl-right .vltl-thongtin{
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}
.main-vltl .main-vltl-right .vltl-thongtin .vltl-thongtin-left{
    float: left;
    width: 60%;
}
.main-vltl .main-vltl-right .vltl-thongtin .vltl-thongtin-right{
    float: left;
    width: 40%;
}
.main-vltl .main-vltl-right .vltl-thongtin .item-vltl{
    display: inline-block;
    width: 100%;
}
.main-vltl .main-vltl-right .vltl-thongtin .item-vltl .col{
    float: left;
}
.main-vltl .main-vltl-right .vltl-thongtin .item-vltl .col.name{
    text-align: right;
    width: 20%;
    padding-right: 5px;
    font-size: 12px;
}
.main-vltl .main-vltl-right .vltl-thongtin .item-vltl .col.txtinput{
    width: 80%;
}
.main-vltl .datagrid-cell,.main-vltl .datagrid-header .datagrid-cell span{
    font-size: 12px !important;
}
.main-vltl .main-vltl-right .vltl-thongtin .vltl-thongtin-right .item-vltl  .col.name{
    text-align: right;
    width: 30%;
    padding-right: 5px;
    font-size: 12px;
}
.main-vltl .main-vltl-right .vltl-thongtin .vltl-thongtin-right .item-vltl .col.txtinput{
    width: 70%;
}

.main-vltl .main-vltl-right .vltl-kq{
    position: relative;
}
.main-vltl .main-vltl-right .title-kq{
    background: #fff;
    padding:5px;
    position: absolute;
    left: 0px;
    top: 0px;
    border: 2px groove;
    border-bottom: none;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px 5px 0px 0px;
}
.main-vltl .main-vltl-right .vltlnote{
    border: 2px groove #f7f7f7;
    padding: 5px;
    margin-top: 30px;
    display: inline-block;
    width: 100%;
}
.main-vltl .main-vltl-right .phacdo{
    float: left;
    width: 30%;
}
.main-vltl .main-vltl-right .phacdo textarea{
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    padding:10px;
}
.main-vltl .main-vltl-right .noidung-dienbien{
    float: left;
    width: 70%;
    padding-left: 10px;
}
.main-vltl .main-vltl-right .dienbien-item textarea{
    width: 100%;
    height: 78px;
    border: 1px solid #ccc;
    padding:10px;
}
.main-vltl .main-vltl-right .dienbien-item textarea.ttrinh{
    width: 100%;
    height: 300px;
    border: 1px solid #ccc;
}
.main-vltl .main-vltl-right .title-pd{
    font-size: 12px;
    font-weight: bold;
}