.KhoDuoc .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.KhoDuoc .ant-select-arrow {
    top: 50% !important;
}
.KhoDuoc .ant-select-single .ant-select-selector .ant-select-selection-item, .KhoDuoc .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}
.KhoDuoc .datetime{
    height: 30px;
}
.KhoDuoc .lablename-input{
    width: 80px;
}
.KhoDuoc .ant-input-number .ant-input-number-input {
    padding: 5px 11px !important;
}
.KhoDuoc .ant-picker{
    border-radius: 0px !important;
    height: 30px;
}
.KhoDuoc .ant-picker .ant-picker-input >input{
    line-height: 28px !important;
}
.khothuoclist .nhomin-act-check{
    display: flex;
}

