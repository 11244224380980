.LoadPage{
    width: 20%;
    height: 20%;
    margin: 25% auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.icon-loading{
    text-align: center;
}