.DatHenOnline .name-dathen{
    width: 80px;
    margin-top: 10px;
}
.DatHenOnline .ant-form-item .ant-form-item-label >label::after {
    margin-top: 10px;
}
.DatHenOnline  .ant-select-arrow {
    top: 50% !important;
}
.imgdathen{
    max-width: 100%;
}