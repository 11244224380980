.danhsachleft-btn {
    svg {
        font-size: 10px;
    }

    border-radius: 0px;
    padding: 0px;
    width: 38px;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.khambenh-danhsach-left {
    position: relative;
    height: 87vh;
}

.khambenh-danhsach-open-left {
    width: 30px;
    height: 50px;
    z-index: 3;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248, 0.5);
    position: absolute;
    right: -30px;
    top: 180px;
    transition: all 0.5s ease-out;

    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}

.khambenh-danhsach-close-left {
    position: absolute;
    right: 0px;
    top: 180px;
    transition: all 1s ease-out;
    width: 30px;
    height: 50px;
    position: absolute;
    z-index: 3;
    left: 0;
    border: 1px solid #0067ac;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 180px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: rgb(248, 248, 248, 0.5);
    transition: all ease-in;

    &:hover {
        background-color: #0067ac;
        color: #fff;
        cursor: pointer;
    }
}

.khambenh-danhsach-left-close {
    transition: all 1s ease-out;
    display: none;
}

.khambenh-danhsach-left-open {
    transition: all 1s ease-out;
}

.dsbenhnhan-left .search-khambenh .lable-date {
    font-size: 12px;
    margin-top: 2px;
    margin-right: 2px;
}

.dsbenhnhan-left .search-khambenh .searh-input {
    display: flex;
    margin-bottom: 12px;
}

.dsbenhnhan-left .search-khambenh .searh-input .txt-input {
    width: 85%;
}

.dsbenhnhan-left .search-khambenh .searh-input .btn-submit {
    width: 15%;
}

.dsbenhnhan-left .search-khambenh .searh-input .btn-submit .danhsachleft-btn {
    border-radius: 0px;
    padding: 0px;
    width: 100%;
    height: 25px !important;

}

.dsbenhnhan-left .search-khambenh .css-xwn1oi-MuiFormControl-root-MuiTextField-root {
    background: #fff;
}

.dsbenhnhan-left .search-khambenh .chon-ngay {
    display: flex;
}

.dsbenhnhan-left .search-khambenh .chon-ngay .date-input {
    display: flex;
}

.dsbenhnhan-left .search-khambenh .chon-ngay .MuiFormControl-fullWidth {
    width: 50%;
}

.dsbenhnhan-left .search-khambenh .chon-ngay .khoangcach {
    width: 5%;
    text-align: center;
}

.dsbenhnhan-left thead.ant-table-thead tr {
    line-height: 20px !important;
}

.custom-table .ant-table-body {
    height: 400px !important;
    /* Chiều cao tùy chỉnh */
    overflow-y: auto !important;
}

.custom-row {
    height: 30px;
    cursor: pointer;
}
/*.ant-table th,
.ant-table td {
  min-width: 100px; 
}
.ant-table th:nth-child(1), .ant-table td::nth-child(1) {
    min-width: 50px;
}*/