.class-nguoigioithieu .textend{
    display: flex;
    margin-bottom: 5px;
}
.class-nguoigioithieu .textend .dskhoa{
    margin-right: 10px;
}
.class-nguoigioithieu .text-secondary{
    font-size: 14px;
    font-weight: 800;
}
.DetailNguoiGT .ant-select-single {
    font-size: 12px;
    height: 28px;
}
.DetailNguoiGT .ant-form-item .ant-form-item-label >label::after {
   display: none;
}
.DetailNguoiGT  .labelname{
    margin-top: 5px;
    margin-right: 5px;
    width: 120px;
}
.DetailNguoiGT  .ant-select-arrow {
    top: 50% !important;
}