.KhaiBaoTTPTTT .MauMoTaCLSBottom{
    display: flex;
    width: 1005;
}
.KhaiBaoTTPTTT .MauMoTaCLSBottom .MauMoTaCLS-left{
    width: 30%;
    padding-bottom: 10px;
    border: 1px groove #0067ac;
    padding: 5px;
    margin-top: 5px;
    height: 94vh;

}
.KhaiBaoTTPTTT .MauMoTaCLSBottom .MauMoTaCLS-right{
    width: 69%;
    padding-bottom: 10px;
    border: 1px groove #0067ac;
    padding: 5px;
    margin-top: 5px;
    height: 94vh;
    margin-left: 2px;
}
.MauMoTaCLSBottom .title{
    font-weight: 700;
    padding: 10px;
}
.MauMoTaCLSBottom .ant-radio-checked .ant-radio-inner{
    width: 16px !important;
    height: 16px !important
}
.MauMoTaCLS-right .rowitem{
    display: flex;
    margin-bottom: 5px;
}
.MauMoTaCLS-right .rowitem .col1{
    width: 13%;
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
}
.MauMoTaCLS-right .rowitem .col2 {
    font-size: 12px;
 }
 .MauMoTaCLS-right .rowitem .col2 .ant-radio-wrapper{
    font-size: 12px;
 }

.MauMoTaCLS-right .rowitem .col2 .ant-select-selector {
    border: none !important;
    background: #ffffff;
}
.MauMoTaCLS-right .rowitem .col2.cate {
   width: 87%;
}
.MauMoTaCLS-right .rowitem .col2 .input-select {
    height: 22px;
 }
 .MauMoTaCLS-right .rowitem .col2  .ant-select-arrow {
    top: 50% !important;
}
.MauMoTaCLS-right .rowitem .rowitem-col {
    display: flex;
    width: 33.3%;
}
.MauMoTaCLS-right .rowitem .rowitem-col .rowitem-col1{
    font-size: 12px;
    text-align: right;
    padding-right: 10px;
    width: 30%;
}


.MauMoTaCLS-right .rowitem .rowitem-col .rowitem-col2{width: 70%;}

.MauMoTaCLS-right .rowitem .rowitem-col .rowitem-col1.bacsy{
    width: 39%;
 }
 .MauMoTaCLS-right .rowitem .rowitem-col .rowitem-col2.bacsy{
     width: 61%;
  }