
.in-nhan-dan {
    width: 100mm;
    height: 80mm;
    padding: 5mm;
    border: 2px solid black;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  
  .in-nhan-dan .label-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .in-nhan-dan .label-text {
    font-size: 14px;
  }
  
  .in-nhan-dan .barcode {
    margin-top: 0px;
  }
  .in-nhan-dan p{
    margin: 0px;
  }
  .in-stt{
    width: 58mm;
    padding: 10px; 
    font-size: 12px
  }
  .in-stt .pagestt{
    text-align: center;
  }
  .in-stt .pagestt div{
    padding: 5px 0px;
  }
  .in-stt .pagestt .name{
    font-size: 16px;
    font-weight: 800;
  }

  .in-stt .pagestt .phong{
    font-size: 20px;
    font-weight: 800;
  }

  .in-stt .pagestt .stt{
    font-size: 20px;
    font-weight: 800;
  }

  /////////
  .inphieu-chi-dinh {
    padding: 10px;
}
.inphieu-chi-dinh .header{
    width: 100%;
    display: flex;
}
.inphieu-chi-dinh .header .header-left{
    float: left;
    width: 25%;
}
.inphieu-chi-dinh .header .header-content{
    float: left;
    width: 50%;
    text-align: center;
}
.inphieu-chi-dinh .header .header-right{
    float: left;
    width: 25%;
}
.inphieu-chi-dinh .thongtin-todieutri ul{
    margin: 0px;
    list-style: none;
    padding: 0px;
    font-size: 13px;
}
.inphieu-chi-dinh .thongtin-todieutri ul li{
    display: inline-block;
    width: 100%;
    padding: 5px 0px;
}
.inphieu-chi-dinh .thongtin-todieutri ul li div{
    float: left;
}
.inphieu-chi-dinh .thongtin-todieutri ul li div.hoten{
    width: 60%;
}
.inphieu-chi-dinh .thongtin-todieutri ul li div.tuoi{
    width: 20%;
}
.inphieu-chi-dinh .thongtin-todieutri ul li div.gioitinh{
    width: 20%;
}
.inphieu-chi-dinh .ketquatodieutri table{
    width: 100%;
}
.inphieu-chi-dinh .ketquatodieutri table tr td{
    border: 1px solid #ccc;
    padding: 6px;
    vertical-align:middle;
    text-align: center;
}
.inphieu-chi-dinh .ketquatodieutri table tbody tr td.tendvkt{
  text-align: left;
}

.inphieu-chi-dinh .ketquatodieutri .stt{
    width: 10%;
    font-weight: 800;
}
.inphieu-chi-dinh .ketquatodieutri .yeucau{
    width: 80%;
    font-weight: 800;
}
.inphieu-chi-dinh .ketquatodieutri .soluong{
    width: 10%;
    font-weight: 800;
}

.inphieu-chi-dinh  .header-cty {
  display: flex;
}
.inphieu-chi-dinh  .header-cty .img{
  width: 20%;
}
.inphieu-chi-dinh  .header-cty .imglogo {
  max-width: 100%;
}
.inphieu-chi-dinh  .header-cty  .thongtin{
  padding-left: 10px;
  width: 80%;
}
.inphieu-chi-dinh  .header-cty  .thongtin div{
  padding: 5px 0px;
}
.inphieu-chi-dinh  .header-cty  .thongtin h1{
  font-weight: 800;
  font-size: 18px;
}
.inphieu-chi-dinh  .doituong{
  font-weight: 800;
  font-size: 14px;
}
.inphieu-chi-dinh .sinhhieu{
  display: inline-block;
  width: 100%;
}
.inphieu-chi-dinh .sinhhieu span{
  float: left;
  width: 25%;
}
.inphieu-chi-dinh .footerbs{
  justify-content: end;
  text-align: end;

}
.inphieu-chi-dinh .footerbs .footerbs-c{
  text-align: center;
}

.inphieu-chi-dinh .footerbs{
  float: right;
  width: 30%;
  text-align: center;
  margin-top: 20px;
}
.inphieu-chi-dinh .footerbs .bs{
 font-weight: bold;
 padding: 10px;
}
.inphieu-chi-dinh .footerbs .ngay, .inphieu-chi-dinh .footerbs .ky{
  font-style: italic;
}
.inphieu-chi-dinh .footerbs .tenbs{
   padding-top: 100px;
 }
