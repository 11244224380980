

.cemaraNoiSoi .ant-modal-header{ 
    background: #e8e8e8 !important;
    padding: 5px 10px;
    height: auto !important;
}
.cemaraNoiSoi  .ant-modal-title{
    width: 100%;
}
.cemaraNoiSoi  .btn-header{
    float: right;
}
.cemaraNoiSoi  .title-header{
    float: left;
}
.cemaraNoiSoi .ant-select-single {
    font-size: 14px;
    height: 20px;
}
.cemaraNoiSoi .ant-select-arrow {
    top: 50% !important;
}
.cemaraNoiSoi .row-height-1{
    height: 33px !important;
}
.cemaraNoiSoi .ant-form-item .ant-form-item-label >label{
    height: auto !important;
    margin-top: 8px;
}
.cemaraNoiSoi .chidinh{
    margin-top: 2px;
}
.cemaraNoiSoi .row-height-tex{
    height: 48px !important;
}