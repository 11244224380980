.DetailDVKTTheoGia .ant-form-item .ant-form-item-label >label::after {
   display: none;
}
.DetailDVKTTheoGia .label-giadvkt{
    width: 100px;
    margin-top: 6px;
    margin-right: 10px;
}
.DetailDVKTTheoGia  .ant-input-number .ant-input-number-input {
   padding-top: 4px !important;
}
.DetailDVKTTheoGia .ant-picker .ant-picker-input >input {
    font-size: 12px !important;
}
.DetailDVKTTheoGia .ant-select-single {
    font-size: 12px;
    height: 28px;
}
.DetailDVKTTheoGia .ant-select-arrow {
    top: 50% !important;
}
.ant-select-selection-placeholder{
    font-size: 12px;
}
.btn-action-giadvkt{
    display: flex;
    justify-content: end;
}
.btn-action-giadvkt .check-dskhoa{
    margin-top: 5px;
}