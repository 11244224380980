.DieuChinhHSBANgoaiTru{
 display: inline-block;
 width: 100%;
 height: 98vh;
}
.DieuChinhHSBANgoaiTru .header-dieuchinh{
    display: flex;
    padding:10px 10px 0px 10px;
}
.DieuChinhHSBANgoaiTru .header-dieuchinh .ngay{
    display: flex;
    width: 20%;
}
.DieuChinhHSBANgoaiTru .header-dieuchinh .ngay .namengay{
    font-size: 12px;
    margin-top: 3px;
}
.DieuChinhHSBANgoaiTru  .categorykhoa{
    position: relative;
    padding-left: 10px;
    width: 30%;
}
.DieuChinhHSBANgoaiTru  .categorykhoa .tenkhoa{
    float: left;
    font-size: 12px;
    width: 20%;
    margin-top: 1px;
}
.DieuChinhHSBANgoaiTru  .categorykhoa .searchkhoa{
    float: left;
    font-size: 12px;
    width: 80%;
}
.DieuChinhHSBANgoaiTru  .categoryphongkham{
    padding-left: 10px;
    width: 35%;
}
.DieuChinhHSBANgoaiTru  .categoryphongkham .namephong{
    float: left;
    font-size: 12px;
    width: 25%;
    margin-top: 3px;
    text-align: right;
}
.DieuChinhHSBANgoaiTru  .categoryphongkham .input-select{
    float: left;
    font-size: 12px;
    width: 75%;
}
.DieuChinhHSBANgoaiTru  .categoryphongkham .ant-select-selector{
    height: 21px !important;
}
.DieuChinhHSBANgoaiTru  .categoryphongkham .form-control.input-select{
    border: 0px !important;
    background: none !important;
}
.DieuChinhHSBANgoaiTru  .categoryphongkham  .ant-select-arrow {
    top: 40% !important;
}

.DieuChinhHSBANgoaiTru  .categorydoituong{
    padding-left: 10px;
    width: 25%;
}
.DieuChinhHSBANgoaiTru  .categorydoituong .namedoituong{
    float: left;
    font-size: 12px;
    width: 25%;
    margin-top: 3px;
    text-align: right;
}
.DieuChinhHSBANgoaiTru  .categorydoituong .input-select{
    float: left;
    font-size: 12px;
    width: 75%;
}
.DieuChinhHSBANgoaiTru  .categorydoituong .ant-select-selector{
    height: 21px !important;
}
.DieuChinhHSBANgoaiTru  .categorydoituong .form-control.input-select{
    border: 0px !important;
    background: none !important;
}
.DieuChinhHSBANgoaiTru  .categorydoituong  .ant-select-arrow {
    top: 40% !important;
}

.DieuChinhHSBANgoaiTru  .categorykhoa .optiontablehide{
    display: none;
}
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow{
    display:block;
    position: absolute;
    top: 21px;
    left: 0px;
    z-index: 9999;
    background: #fff;
    width: 500px;
}
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100{
    border: 1px solid #ccc;
}
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100{
    border: 1px solid #ccc;
}
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 thead th{
     border-right: 1px solid #ccc;
     padding: 5px;
     background: #eaf2ff;
} 
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 thead th.khoa{
    border-right:none;
} 
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 tbody td{
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 5px;
} 
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 tbody td.khoa{
   border-right:none;
} 
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 .ma{
   width: 50px;
} 
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 tbody tr{
   cursor: pointer;
} 
.DieuChinhHSBANgoaiTru  .categorykhoa .optiontableshow .w-100 tbody tr:hover{
    background: #eaf2ff;
    color: #000;
 } 
 .DieuChinhHSBANgoaiTru .header-row2{
    display: flex;
 }


 .DieuChinhHSBANgoaiTru  .categorybacsy{
    padding-left: 10px;
    width: 30%;
}
.DieuChinhHSBANgoaiTru  .categorybacsy .namebacsy{
    float: left;
    font-size: 12px;
    width: 13%;
    margin-top: 3px;
}
.DieuChinhHSBANgoaiTru  .categorybacsy .input-select{
    float: left;
    font-size: 12px;
    width: 87%;
}
.DieuChinhHSBANgoaiTru  .categorybacsy .ant-select-selector{
    height: 21px !important;
}
.DieuChinhHSBANgoaiTru  .categorybacsy .form-control.input-select{
    border: 0px !important;
    background: none !important;
}
.DieuChinhHSBANgoaiTru  .categorybacsy  .ant-select-arrow {
    top: 40% !important;
}
.DieuChinhHSBANgoaiTru  .categoryloaihs{
    padding-left: 10px;
    width: 15.8%;
}
.DieuChinhHSBANgoaiTru  .categoryloaihs .nameloaihs{
    float: left;
    font-size: 12px;
    width: 30%;
    margin-top: 3px;
}
.DieuChinhHSBANgoaiTru  .categoryloaihs .input-select{
    float: left;
    font-size: 12px;
    width: 70%;
}
.DieuChinhHSBANgoaiTru  .categoryloaihs .ant-select-selector{
    height: 21px !important;
}
.DieuChinhHSBANgoaiTru  .categoryloaihs .form-control.input-select{
    border: 0px !important;
    background: none !important;
}
.DieuChinhHSBANgoaiTru  .categoryloaihs  .ant-select-arrow {
    top: 40% !important;
}

.DieuChinhHSBANgoaiTru .header-row2 .ant-form-item-control-input {
    min-height: 20px !important;
}
.DieuChinhHSBANgoaiTru .header-row2 .ant-form-item .ant-form-item-label >label{
    height: 10px !important;
}

.DieuChinhHSBANgoaiTru .header-row2  .checkin{ width: 10%; margin-left: 10px; }
.DieuChinhHSBANgoaiTru  .btnDieuChinhHSBA  .form-btn {
    border-radius: 0px;
    height: 20px !important;
}