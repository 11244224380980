@import "~rc-easyui/dist/themes/default/easyui.css";
@import "~rc-easyui/dist/themes/icon.css";
@import "~rc-easyui/dist/themes/react.css";

thead.ant-table-thead {
    line-height: 10px;
    font-size: 12px;
    tr {
        padding: 0px !important;
        line-height: 10px;
        td {
            line-height: 10px;
        }
    }
}

a {
    text-decoration: none;
}
//
.scroll {
    overflow-y: scroll;
    // height: 100vh;
}
.scroll::-webkit-scrollbar {
    display: none;
}
.scroll {
    scrollbar-width: none;
}
//tab
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    font-weight: bold;
}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #0067ac !important;
    border-radius: 0px !important;
}
.ant-tabs-tab {
    color: #0067ac !important;
    border-radius: 0px !important;
    border: 2px solid #0067ac !important;
}
.ant-tabs-nav {
    margin: 0px !important;
}
/* table */

.ant-table-tbody > tr > td {
    height: 0px !important;
    padding: 0px 5px !important;
    font-size: 12px;
}
.my-tag {
    font-size: 12px;
}
.ant-table-header {
    border-radius: 0px !important;
    border: 1px silver solid;
}
.ant-table-thead > tr > th {
    border-radius: 0px !important;
    height: 0px !important;
    padding: 3px 10px !important;
    font-size: 12px;
    color: #0067ac !important;
    background-color: #ebebeb !important;
}
.ant-table-tbody tr:hover td {
    background-color: #0067ac !important;
    color: #fff;
}

//select
// .ant-select-selector {
//     height: 20px !important;
// }
input[type="search"] {
    height: 25px !important;
    border-radius: 0px !important;
}
.input-select .ant-select .ant-select-selector {
    border-radius: 0px !important;
}

//checkbox
.ant-checkbox-checked .ant-checkbox-inner {
    border: #0067ac !important;
    background-color: #0067ac !important;
}
//radio
.ant-radio-checked .ant-radio-inner {
    border: #0067ac !important;
    background-color: #0067ac !important;
    height: 10px;
    width: 10px;
}
//label input
.form-input-label {
    font-size: 12px !important;
}
//button
.form-btn {
    border-radius: 0px;
    padding: 10px 15px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: #0067ac 1px solid;
    background: #0067ac;
    color: #fff;
}
.form-btn-luu {
    border-radius: 0px;
    padding: 10px 15px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0067ac !important;
    color: #fff !important;
    border: #0067ac 1px solid;
    &:hover {
        background-color: #fff !important;
        color: #0067ac !important;
        border: #0067ac 1px solid;
    }
}
button.ant-btn.css-dev-only-do-not-override-pr0fja.ant-btn-default.ant-dropdown-trigger {
    color: #0067ac;
    border: 1px solid #0067ac;
}

button:hover {
    background-color: #e2e2e2;
    color: #4096ff !important;
}

// input
.form-control {
    font-size: 11px !important;
    padding: 1px 4px !important;
    border-radius: 0px !important;
    &:focus {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
    }
}
.form-control-login {
    border-radius: 0px !important;
    font-size: 12px !important;
    background-color: rgba(255, 255, 255, 0.8);
}
.form-control-select {
    font-size: 12px !important;
    padding: 0 !important;
    border-radius: none !important;
}

//label
.form-input-label {
    font-size: 12px;
}
//row
.form-row {
    height: 23px !important;
}
.form-row-login {
    height: 14px !important;
}
.form-row-textarea {
    height: 30px !important;
    margin-top: 7px !important;
}
//background
.bg {
    background-color: #0067ac;
    color: #fff;
    &:hover {
        border: 1px solid #0067ac;
        color: #0067ac !important;
        background-color: #fff;
    }
}
.bg-box {
    &.bg-xanh {
        background-color: #1c80c3;
    }
    &.bg-xam {
        background-color: #f2f2f2;
    }
}
//border
.color-border {
    border: 1px solid #0067ac;
}
.color-border-xam {
    border: 1px solid #bcbcbc;
}
//border left
.border-line-left {
    border-left: 1px solid silver;
}
// bg title
.bg-title {
    background-color: #0067ac;
    color: #fff;
}
.bg-label {
    background-color: #f3f0f0;
}
//tab
.ant-tabs-tab {
    padding: 2px 10px !important;
}
//text
.color-text {
    color: #0067ac;
}
//btn inout
.form-input-btn {
    border-radius: 0px;
    padding: 2px 4px;
    height: 20px;
    display: flex;
    align-items: center;
    border: #0067ac 1px solid;
}
//shadow
.div-shadow {
    &.v3 {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    &.v5 {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    &.v6 {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    &.v10 {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
            rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
    &.v11 {
        border: 1px solid black;
    }
}

//collapse
.ant-collapse-header {
    border-radius: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    display: flex !important;
    align-items: center !important;
    background-color: #d0d0d0 !important;
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    border-radius: 0px;
}
.ant-collapse-item {
    border-radius: 0px !important;
    border: 1px solid #ebebeb !important;
    padding: 0px !important;
}
.ant-collapse.ant-collapse-icon-position-start.ant-collapse-small {
    border-radius: 0px !important;
}
.ant-collapse-content-box {
    padding: 0px !important;
}
//selet
.ant-select-dropdown .ant-select-item {
    position: relative;
    display: flex;
    min-height: 20px;
    padding: 2px 12px;
    font-size: 12px;
}

.ant-select-selector {
    border-radius: 0px !important;
}
.ant-select-selection-search {
    font-size: 12px !important;
}
.ant-select-selection-item {
    font-size: 12px !important;
}

// modal
.modal-label {
    font-size: 14px !important;
    font-weight: bold;
    padding: 0px !important;
    margin: 0px !important;
    line-height: 0px !important;
}
.ant-modal-header {
    height: 30px;
    margin: 0;
}
.ant-modal-content {
    //padding: 12px 20px !important;
    padding: 0px !important;
}
.ant-modal-header{
    padding: 10px;
    background: #4096ff !important;
    color: #fff;
    display: flex;
    margin-bottom: 0px !important;
}
.ant-modal-body{
    padding: 15px !important;
}
.ant-form-item{
    margin-bottom: 0px !important;
}

//dsleft
.scroll-dsleft {
    overflow-y: scroll;
    // max-height: 100vh;
}
.scroll-dsleft::-webkit-scrollbar {
    display: none;
}
.scroll-dsleft {
    scrollbar-width: none;
}
// max

.h-max {
    max-height: 100vh;
}
.tiepnhan-btn-thongtinkhambenh .ant-btn{
    height: 25px !important ;
}
.datagrid-header .datagrid-cell span {
   color: #0067ac;
}

