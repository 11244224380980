.khambenh-phieukham-right-input-label {
    width: 85px;
}
.khambenh-phieukham-left-input-label {
    width: 88px;
}
.khambenh-phieukham-right-input-label-cannang {
    width: 60px;
}
.border-input {
    border: none !important;
}
.tabphieukham-position {
    position: relative;
}
.phieukham-input-position {
    position: absolute;
    right: 0;
    top: -5px;
    z-index: 1;
}
.khambenh .ant-select-selector {
    height: 21px !important;
    margin: 6px 0px !important;
}

.khambenh .ant-select-arrow {
    top: 55% !important;
}
.khambenh .select-khb{
    margin-bottom: 5px;
}
.border-line-left-kb{
    border-left: 1px solid #ccc;
    padding-left: 4px;
    margin-left:4px;
}
.kb-loidan{
    margin-bottom: 15px;
}
.kb-loidan .ant-form-item-control-input{
    min-height: 0px !important;
}
.khambenh-label label{
    margin-top: 8px;
    height: auto !important;
}

.khambenh-label .kb-loidan label{

    margin-top: -8px;
}
.pk-tt-row label{
    float: left;
    width: 31%;
    font-size: 12px;
    text-align: right;
    padding-right: 5px;
}
.pk-tt-row.tong-dich-vu label{
    width: 33%;
}
.pk-tt-row input{
    float: left;
    width: 69%;
}
.pk-tt-row.tong-dich-vu  input{
    width: 67%;
}
.phieukham-input-position{
    margin-top: 5px;
}
.ant-modal-body iframe{
    width: 100%;
    height: 600px;
}
.page-kham-benh .tab-tien-ich .form-btn{
    margin-right: 2px;
    border: #0067ac 1px solid;
    background: #fff;
    color: #000;
}
.page-kham-benh .tab-tien-ich button:hover {
    color: #0067ac !important;
    border: 1px solid #0067ac !important;
}
.btnaction-kb .form-btn{
    padding: 10px 5px;
    margin-left: 3px;
}
.btn-action-kham-benh{
    position: fixed;
    top:0px;
    width: 100%;
    left: 0px;
    z-index: 2;
    
}
.btn-action-kham-benh button{
    margin-left: 3px;
    border-radius: 0px;
    background: #0067ac;
    color: #fff;
    font-size: 12px;

}
.btn-action-kham-benh button:hover {
    color: #000 !important;
}
.btn-action-kham-benh .ant-btn{
    padding: 4px 8px !important;
}

.page-kham-benh-main{
    margin-top: 30px;
}
.sinh-hieu-kham-benh .khambenh-phieukham-right-input-label ,.sinh-hieu-kham-benh  .khambenh-phieukham-right-input-label-cannang{
    margin-top: 10px;
}
.sinh-hieu-kham-benh .ant-form-item .ant-form-item-label >label::after {
   margin-top: 10px;
}
.page-kham-benh.disabled{
    pointer-events: none;
}
.page-kham-benh.disabled input, .page-kham-benh.disabled select{
    background: #f0f0f0;
}
.page-kham-benh.disabled .btn-action-kham-benh button{
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25)
}
.page-kham-benh.disabled button.STTKeTiep{
    pointer-events: auto;
    background: #0067ac;
    color: #fff;
}
.tab-cd-thuoc-kham-benh{
    //height: 32.2vh;
}
// icd10
.icd10-search{
    position: relative;
}
.icd10-search .absolute {
    position: absolute;
    z-index: 99;
    right: 0;
    width: 100%;
    background: #fff;
}

.w-full {
    width: 100%;
    border: 1px solid 1 #d9d9d9 !important;
    border-radius: 0px;
}

.icd10-search thead>tr {
    position: relative;
    display: flex;
    background: #f0f0f0;
}

.icd10-search thead tr td.ma {
    width: 14.8%;
    max-width: 14.8%;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.icd10-search thead>tr td.ten {
    width: 85%;
    max-width: 85%;
    border-right: 1px solid #ccc;
}

.icd10-search tbody td.ma {
    width: 15%;
    max-width: 15%;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.icd10-search tbody td.ten {
    width: 85%;
    border-right: 1px solid #ccc;
}

.icd10-search .table-responsive {
    border-collapse: collapse;
    width: 100%;
    display: block;
}

.icd10-search .table-responsive thead {
    display: inline-block;
    width: 100%;
}

.icd10-search .table-responsive tbody {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 140px;
}
.icd10-search .table-responsive tbody tr {
   cursor: pointer;
   width: 100%;
   display: flex;
}
.icd10-search .table-responsive tbody tr:hover {
    background: #f0f0f0;
 }

.icd10-search .table-responsive thead {
    position: sticky;
    top: 0;
}

.icd10-search .input-icon {
    position: relative;
}

.icd10-search table tr td {
    padding: 3px;
    font-size: 12px;
}

.icd10-search .input-icon .w-full::placeholder {
    font-size: 12px;
}
.icd10-search .input-icon .w-full {
    font-size: 12px;
}

.icd10-search .iconsearch{
    position: absolute;
    right: 10px;
    top: 5px;
    color: rgba(0, 0, 0, 0.25);
}
.icd10-search .iconsearchxoa{
    cursor: pointer;
}
.icd10-search  .inputsearch{
    height: 22px;
}
// chi dinh kham benh


.table-chidinhkb{
    width: 100%;
    max-height: 300px; 
    overflow-y: auto; 
    border: 1px solid #ccc;
}

.table-chidinhkb table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; 
}

.table-chidinhkb thead {
    position: sticky;
    top: 0;
    background:#f0f0f0;
    z-index: 2;
}

.table-chidinhkb th,.table-chidinhkb td {
    padding:4px;
    text-align: left;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    width: 10%;
    font-size: 12px;
}
.table-chidinhkb td.tendvkt {
    width: 60%;
}
.table-chidinhkb td.goikham {
    width: 8%;
    text-align: center;
}

.table-chidinhkb tbody tr:nth-child(even) {
  
}


.chi-dinh-search{
    position: relative;
}
.chi-dinh-search .absolute {
    position: absolute;
    z-index: 99;
    right: 0;
    width: 100%;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.table-chidinhkb.top {
    bottom: 100%; 
}
.chi-dinh-search .input-icon {
    position: relative;
}
.chi-dinh-search .input-icon .iconsearch {
    position: absolute;
    top:10px;
    right:10px;
    color: #ccc;
}
.check-huy-cd{
    display: flex;
    justify-content: end;
    text-align: end;
    font-size: 12px;
    margin-top: 3px;
}
.check-huy-cd .check-dvkt{
    margin-right: 10px;
    display: flex;
}
.check-huy-cd .check-dvkt .checkgiadv{
    margin-top: 5px;
}
.check-huy-cd .check-dvkt span{
    margin-top: 8px;
    padding-left: 5px;
}

.class-cd input, .class-cd button, .class-cd .checkgiadv{
    background: #f0f0f0 !important;
}
.check-huy-cd .bnthuy {
    font-size: 12px;
    padding:3px 5px;
    border-radius: 0px;
}