.TiemChung .ant-radio-checked .ant-radio-inner{
    width: 16px !important;
    height: 16px !important
}
.TiemChungTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 400 !important;
}
.TiemChungTab  .ant-tabs-tab {
    padding: 2px 5px !important;
}
.TiemChungTab .kbyhct-tab {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 4px;
}
.TiemChungTab{
    border: 1px solid #0067ac;
}
.TiemChungTab .title{
    font-size: 12px;
    font-weight: 700;
    padding: 5px;
    border-bottom: 1px solid #0067ac;
}
.TiemChungTab .TiemChungTT{
    display: flex;
    width: 100%;
    margin-top: 10px;
}
.TiemChungTab .TiemChungTT .TiemChungTTCol1{
    width: 40%;
}
.TiemChungTab .TiemChungTT .TiemChungTTCol2{
    width: 40%;
}
.TiemChungTab .TiemChungTT .TiemChungTTCol3{
    width: 20%;
    padding-right: 5px;
}
.TiemChungTab .TiemChungTT .itemTT{
    display: flex;
    margin-bottom: 3px;
}

.TiemChungTab .TiemChungTT .itemTT .namett{
    width: 25%;
    font-size: 12px;
    text-align: right;
    padding-right: 5px;
}

.TiemChungTab .TiemChungTT .itemTT .inputtt{
    width: 75%;
}
.TiemChungTab .TiemChungTT .itemTT .ant-select-selector {
    border: none !important;
    background: #ffffff;
}
.TiemChungTab .TiemChungTT .itemTT .selectac{
    height: 22px;
}
.TiemChungTab .TiemChungTT .itemTT .ant-select-arrow {
    top: 50% !important;
}

.TiemChungTab .TiemChungTT .itemTT .namecs{
    width: 50%;
    font-size: 12px;
    text-align: right;
    padding-right: 5px;
}

.TiemChungTab .TiemChungTT .itemTT .inputcs{
    width: 50%;
}
.TiemChungBottom .ant-tabs-tab {
    color: #000 !important;
    border-radius: 0px !important;
    border: 1px solid #0067ac !important;
}
.TiemChungBottom .ant-tabs-tab.ant-tabs-tab-active {
    border: 1px solid #0067ac;
    background-color: #0067ac !important;
}
.TiemChungBottom .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    font-weight: 400;
}
.khaibaotiemchung{
    display: flex;
    width: 100%;
}
.khaibaotiemchung .kbtiemchungleft{
    width: 45%;
    border: 1px solid #0067ac;
    height: 93vh;
}
.khaibaotiemchung .kbtiemchungleft .title{
    font-weight: 700;
    padding: 10px;
    border-bottom: 1px solid #0067ac;
}
.khaibaotiemchung .kbtiemchungright{
    width: 54.5%;
    border: 1px solid #0067ac;
    height: 93vh;
    margin-left:2px;
}
.khaibaotiemchung .kbtiemchungright .title{
    font-weight: 700;
    padding: 10px;
    border-bottom: 1px solid #0067ac;
}
.bototm-tiemchung {
    padding:10px;
}
.bototm-tiemchung .rowitem-ltiemchung{
    display: flex;
}
.bototm-tiemchung .rowitem-ltiemchung .nameltc{
    width: 20%;
    font-size: 12px;
}
.bototm-tiemchung .rowitem-ltiemchung .inpultc{
    width: 80%;
}