.DMThuocVTYT .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.DMThuocVTYT .ant-select-arrow {
    top: 50% !important;
}
.DMThuocVTYT .ant-select-single .ant-select-selector .ant-select-selection-item, .DMThuocVTYT .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}
.DMThuocVTYT .lablename-input{
    width: 110px;
}
.DMThuocVTYT .lablename-input.maKQDT{
    width: 310px;
}
.ant-table-thead tr th{
    width: 200px;
}
.DMThuocVTYT .ant-input-number .ant-input-number-input{
    padding: 5px 11px !important;
}
.list-style-thuoc .ant-table-expanded-row-fixed{
    width: 5000px !important;
    text-align: left;
}
/*
.datagrid-cell, .datagrid-cell-group, .datagrid-header-rownumber, .datagrid-cell-rownumber{
    white-space: pre-line !important;
    
}*/


.list-style-thuoc .datagrid-field-td:nth-child(3), .list-style-thuoc .datagrid-filter-row td:nth-child(3){
    position: sticky;
    left: 0;
    background: #f5f5f5;
    z-index: 1;
}
.list-style-thuoc .datagrid-td:nth-child(3){
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 1;
}
.ant-form-item-explain-error {
    text-align: left;
}
.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "";
}

.ListNhomKho span::before {
    display: inline-block;
    margin-inline-end: 4px;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: ",";
}
.ListNhomKho span:nth-child(1)::before {
    display: inline-block;
    margin-inline-end: 4px;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "";
}
.list-style-thuoc .datagrid-body-inner{
  display: inline-block;
}
.datagrid-header .datagrid-cell span{
    font-size: 12px !important;
    font-weight: 700;
}
.datagrid-cell{
    font-size: 12px !important;
}
