.vienphi-danhmuc-title {
    font-weight: bold;
}

//icon
.vienphi-danhmuc-icon-modify {
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
}

//
.scroll {
    overflow-y: scroll;
    // height: 100vh;
}

.scroll::-webkit-scrollbar {
    display: none;
}

.scroll {
    scrollbar-width: none;
}

//
.vienphi-danhmuc-btn {
    background-color: #0067ac;
    color: #fff;
}

.vienphi-danhmuc-add {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 5px 10px;
}

.danhmuc thead.ant-table-thead tr {
    line-height: 25px !important;
}

.danhmuc .ant-table-tbody>tr>td {
    line-height: 30px;
    border-right: 1px solid #f0f0f0;
}

.danhmuc_nhomvpht .ant-table-tbody>tr>td:nth-child(4) {
    width: 100px;
}

.danhmuc_nhomvppk .ant-table-tbody>tr>td:nth-child(5) {
    width: 100px;
}

.ant-table-wrapper .ant-table-cell-fix-right {
    width: 100px !important;
}

.ant-modal .ant-modal-close {
    top: 0px !important;
}
.popupdskham .ant-modal-close {
    top: 12px !important;
    right:20px;
    color: #fff;
}
.inputdm {
    height: 30px;
}
.lablename{
    margin-top: 16px;
}
.lablename-input{
    margin-top: 10px;
}

 .dmdot .ant-form-item .ant-form-item-label >label::after {
    content: "";
 }
.dmname-w .ant-col{
    width: 120px;
 }



.ant-form-item-control-input {
    min-height: 40px !important;
}

.dmdot .ant-select-selector {
    margin: 6px 0px !important;
    height: 27px !important;
}

.ant-select-arrow {
    top: 70% !important;
}
.dmvp-1 .lablename-input, .dmvp-1  .lablename{
    width: 120px;
}
.dmvp-1 .lablename-9324_A{
    width: 200px;
}
.banggiadvkt{
   padding-right: 5px;
}
.chitietgia{
    padding-left: 10px;
}
.namekhaibao{
    font-weight: bold;
    padding: 5px;
}
.DetailLoaiVienPhi .ant-input-number .ant-input-number-input{
    padding: 5px 11px !important;
}
.ant-table-expanded-row-fixed{
    font-size: 16px;
}
.StyleNhomIn .ant-checkbox-wrapper{
    margin-left: 30px;
}
.StyleNhomIn .nhomin-act-check{
    display: flex;
}
.StyleNhomIn .nhomin-act-check .col-n{
    padding-left: 20px;
}
.StyleLoaiVienPhi .ant-checkbox-wrapper{
    margin-left: 30px;
}
.StyleLoaiVienPhi .nhomin-act-check{
    display: flex;
}
.StyleLoaiVienPhi .nhomin-act-check .col-n{
    padding-left: 20px;
}

.StyleLoaiVienPhi  .ant-radio-checked .ant-radio-inner {
    width: 16px;
    height: 16px;
}
.ant-radio-checked .ant-radio-inner {
    width: 16px !important;
    height: 16px !important;
}
.SearchDonViTinh{
    width: 50%;
}
.SearchDonViTinh .form-control{
    border: 0px !important;
    background: transparent !important;

}
.SearchHangSanXuat{
    width: 50%;
}
.SearchHangSanXuat .form-control{
    border: 0px !important;
    background: transparent !important;

}
.listgiadvkt .text-end{
    display: flex;
}
.listgiadvkt .text-end .col-n{
    margin-top: 4px;
}
.tablegiadvkt .datagrid-field-td:nth-child(1), .tablegiadvkt .datagrid-filter-row td:nth-child(1){
    position: sticky;
    left: 0;
    background: #f5f5f5;
    z-index: 1;
}
.tablegiadvkt .datagrid-td:nth-child(1){
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 1;
}
.tablegiadvkt .datagrid-row-over .datagrid-td:nth-child(1){
    position: sticky;
    left: 0;
    background: #eaf2ff;
    z-index: 1;
}
.tablegiadvkt  .datagrid-row-over,
.tablegiadvkt  .datagrid-header td.datagrid-header-over {
  background: #eaf2ff;
  color: #000;
  cursor: default;

}
.ant-select-single .ant-select-selector .ant-select-selection-item, 
.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    text-align: left;
}
.DetailGiaDVKT .dmdot .ant-select-selector{
    margin: 0px !important;
}
.DetailGiaDVKT .ant-select-arrow {
    top: 50% !important;
}
.DetailGiaDVKT .ant-input-number .ant-input-number-input {
    padding-top: 3px !important;
}
.DetailGiaDVKT .label-giadvkt {
    width: 100px;
    margin-top: 7px;
}
.DetailGiaDVKT  .ant-form-item-control-input{
    min-height: 35px !important;
}
.DetailGiaDVKT .ant-form-item .ant-form-item-label >label::after {
    content: " ";
    margin-left: 0px;
    margin-right: 10px;
 }
 .DetailGiaDVKT .ant-select-single{
    font-size: 12px;
    height: 26px;
 }
 .DetailGiaDVKT .ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
}
.DetailGiaDVKT .ma-input{ margin-left: 3px; width: 99%;}