.color-icon-edit {
    &.yellow {
        svg {
            color: #ffc107 !important;
        }
    }
    &.red {
        svg {
            color: rgb(233, 58, 58) !important;
        }
    }
    &.blue {
        svg {
            color: rgb(7, 63, 246) !important;
        }
    }
    &.green {
        svg {
            color: rgb(9, 180, 49) !important;
        }
    }
    &.black {
        svg {
            color: rgb(29, 29, 29) !important;
        }
    }
}

.khambenh-form-input-label {
    width: 50px;
}

.tab-khambenh {
    //tab
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff !important;
        font-weight: bold;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        background-color: #0067ac !important;
        border-radius: 0px !important;
    }
    .ant-tabs-tab {
        color: #0067ac !important;
        border-radius: 0px !important;
        border: 2px solid #0067ac !important;
        padding: 0px 10px !important;
        margin: 0px;
    }
    .ant-tabs-nav {
        margin: 0px !important;
    }
}
.khambenh-btn-icon {
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
}
li.ant-dropdown-menu-item {
    padding: 2px 10px !important;
}
.div-shadow.v5{
    margin-top: 0.5rem;
}
.tiepnhan-congkham input{
    height: 26px;
}
.tiepnhan-congkham .form-row {
    height: 28px !important;
}
.pk-profile label{
    margin-top: 5px;
}
.kb-loidan label{
    height: auto !important;
}

.kb-checkintoa{
    display: inline-block;
    float: left;
    padding-right: 10px;
    font-size: 12px;
}
.checkintoa-name{
    margin-top: 2px;
}
.check-1{
    margin-top: 0px;
}
/////
.bg-chon-phong-thuc-hien {
   
}
.box-chon-phong {
    width: 45%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin: 0 auto;
    .title {
        display: flex;
        align-items: center;
        background-color: rgb(0, 103, 172,0.9);
        padding: 10px;
        div {
            font-size: 14px;
            color: #fff;
            margin: 0px 10px;
            font-weight: bold;
        }
    }
    .content {
        border: 1px solid #0067ac;
        background-color: rgb(255, 255, 255, 0.7);
        padding-bottom: 40px;
        .box-input {
            padding: 20px 10px 0px 10px;
        }
    }
    .select-login {
        .ant-select-selector {
            height: 30px !important;
            margin: 0px !important;
        }
        input[type="search"] {
            border-radius: 0px !important;
        }
        .input-select .ant-select .ant-select-selector {
            border-radius: 0px !important;
        }
    }
    .w-label {
        width: 100px;
        font-weight: bold;
        margin-top: 8px;
    }
   
    .btn-login {
        background-color: #d90044;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        display: flex;
        align-items: end;
        text-align: right;
        margin-right: 10px;
    }
}
.inputrow{
    width: 100%;

}
.inputrow .form-row-login{
    height: 34px !important;
}

.btn-login:hover{
    color: #000 !important;
}

.box-chon-phong .ant-form-item .ant-form-item-label > label::after{ margin-top: 6px;}
.box-chon-phong.ant-select-arrow {top: 50% !important;}
.box-chon-phong .ant-select-single{
    height: 26px !important;
}
.box-chon-phong  .ant-select-arrow {
    top: 50% !important;
}
.box-chon-phong .log-chon-phong{
    display: flex;
}
.iconuser{
    width: 20%;
    margin-top: 20px;

}
.box-chon-phong .box-input{
    width: 80%;
}