.StyleDuongDung .nhomin-act-check {
    display: flex
;
}
.StyleDuongDung .nhomin-act-check .col-n {
    padding-left: 20px;
}
.StyleDuongDung  .optiontablehide{
    display: none;
}
.StyleDuongDung  .optiontableshow{
    display:block;
    position: absolute;
    top: 120px;
    left: 700px;
    z-index: 9999;
    background: #fff;
    width: 500px;
}
.DetailDuongDung .ant-input-number .ant-input-number-input {
    padding: 4px 11px !important;
}
.DetailDuongDung .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.DetailDuongDung .ant-select-arrow {
    top: 50% !important;
}
.DetailDuongDung .ant-select-single .ant-select-selector .ant-select-selection-item, .DetailDuongDung .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}
