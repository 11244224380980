.DetalPhanNhomDuoc .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.DetalPhanNhomDuoc .ant-select-arrow {
    top: 50% !important;
}
.DetalPhanNhomDuoc .ant-select-single .ant-select-selector .ant-select-selection-item, .DetalPhanNhomDuoc .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}
.DetalPhanNhomDuoc  .item{
    margin-bottom: 5px;
}
.DetalPhanNhomDuoc  .item .name{
    float: left;
    font-size: 12px;
    width: 25%;
    margin-top: 10px;
    
}
.DetalPhanNhomDuoc  .item .selectac{
    float: left;
    width: 75%;
    font-size: 12px;
    outline:none;
    border: 0px !important;
    
}
.DetalPhanNhomDuoc .input-label-khaibaouser{
    margin-top: 5px;
}
.css-13cymwt-control, .css-t3ipsp-control{
    height: 20px !important;
    min-height: 32px !important;
    border-radius: 0px !important;
}
.css-t3ipsp-control{
    border: 0px !important;
    text-align: left;
    align-items: inherit;
    border-radius: 0px !important;
}
.css-10wo9uf-option, .css-1jqq78o-placeholder{
    text-align: left;
    font-size: 12px;
    text-transform: none;
}
.css-d7l1ni-option, .css-1dimb5e-singleValue, .css-tr4s17-option{
    text-align: left;
    font-size: 12px;
    text-transform: none;
    padding-top: -6px !important;
}
.css-19bb58m{
    grid-area: 0 !important; 
}
.css-1dimb5e-singleValue{
    padding-top: -6px !important;
    grid-area: 0 !important; 
}
.DetalPhanNhomDuoc  .item .inputdm{
    float: left;
    width: 75%;
    font-size: 12px;
}
.css-t3ipsp-control:hover{
    border: 0px !important;
}
.css-t3ipsp-control{
    border: 1px solid #ccc !important;
    box-shadow: 0px !important;
    box-sizing: border-box;

}
.css-15lsz6c-indicatorContainer{
    padding: 5px !important;
}
.css-1xc3v61-indicatorContainer{
    padding: 5px !important;
}
.css-1u9des2-indicatorSeparator{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.css-hlgwow, .css-19bb58m{
    padding: 2px !important;
}
.css-19bb58m{
    margin: 0px !important;
}
.ServiceGroupID{
    display: none;
}
.DetalPhanNhomDuoc .ant-input-number .ant-input-number-input{
    padding: 5px 11px !important;
}