.KhamBenhYHCT .ant-radio-checked .ant-radio-inner{
    width: 16px !important;
    height: 16px !important
}
.KhamBenhYHCTTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 400 !important;
}
.KhamBenhYHCTTab  .ant-tabs-tab {
    padding: 2px 5px !important;
}
.KhamBenhYHCTTab .kbyhct-tab {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 4px;
}
.FromKhamBenhYHCT{
    display: flex;
    width: 100%;
}
.FromKhamBenhYHCT .w-40{
    width: 40%;
    padding-bottom: 10px;
    border: 1px groove #0067ac;
    padding: 5px;
    background: #eaf2ff;
 
}
.FromKhamBenhYHCT .w-60{
    width: 59.5% !important;
    padding-left: 4px;
    border: 1px groove #0067ac;
    padding: 5px;
    margin-left: 2px;
    background: #eaf2ff;

}
.FromKhamBenhYHCT .form-control.input-select{
    border: 0px !important;
    padding: 1px 0px !important;
}
.FromKhamBenhYHCT .ant-form-item .ant-form-item-label >label::after {
    content: "" !important;
}
.FromKhamBenhYHCT .ant-select-single {
    font-size: 12px;
    height: 28px;
}
.FromKhamBenhYHCT .ant-select-arrow {
    top: 50% !important;
}
.FromKhamBenhYHCT .formrow{
    height: 30px;
}
.FromKhamBenhYHCT .tableICDKT{
    margin-top: 5px;
    padding-right: 4px;
}
.FromKhamBenhYHCT textarea.form-control {
    min-height: 25px;
}
.FromKhamBenhYHCT  .formrowauto{
    margin-top: 5px;
}
.FromKhamBenhYHCT .formrowin{
    height: 23px;
}
.FromKhamBenhYHCT .khambenhitem{
    display: flex;
    margin-top: 10px;
}
.FromKhamBenhYHCT .khambenhitem .khambenhitem-1{
    width: 60%;
}
.FromKhamBenhYHCT .khambenhitem .khambenhitem-2{
    width: 40%;
}
.FromKhamBenhYHCT .khambenhitem .title{
    font-weight: 700;
    font-size: 12px;
}
.FromKhamBenhYHCT .khambenhitem .title-tt{
    font-size: 12px;
}
.FromKhamBenhYHCT .cacbophan .title-tt{
    font-size: 12px;
}
.FromKhamBenhYHCT .khambenhitem-2 .ant-form-item-label{
    float: left;
    width: 48%;
}
.FromKhamBenhYHCT .khambenhitem-2 .ant-form-item-control{
    float: left;
    width: 52%;
}
.FromKhamBenhYHCT .khambenhitem-2 .namelable{
    font-size: 12px;
    margin-top: 7px;
}
.FromKhamBenhYHCT  .formrowkb{
    height: 23px;
}
.FromKhamBenhYHCT .formrow-s{
    height: 26px;
}
.FromKhamBenhYHCT .iteminput{
    height: 23px;
}
.FromKhamBenhYHCT .w-60 .title{
    font-weight: 700;
    font-size: 12px;
    margin-top: 10px;
}
.FromKhamBenhYHCT .w-40 .title{
    font-weight: 700;
    font-size: 12px;
}
.FromKhamBenhYHCT .lablenameyhct{
    width: 80px;
}
.FromKhamBenhYHCT .thietchan{
    font-size: 12px;
    margin-top: 8px;
}
.FromKhamBenhYHCT  .lablenameyhct-iv{
    margin-top: 5px;

}
.FromKhamBenhYHCT .item-pp .title-1{
    font-size: 12px;
    margin-top: 10px;
}
.FromKhamBenhYHCT .item-pp .title-2{
    font-size: 12px;
}
.FromKhamBenhYHCT .item-pp .m-0{
    margin-top: -10px !important;
}
.FromKhamBenhYHCT .item-pp .texarea{
    margin-top: 10px;
}
//tong ket ho so

.FromTKHSYHCT{
    display: flex;
}
.FromTKHSYHCT .title{
    font-weight: 700;
    font-size: 12px;
}
.FromTKHSYHCT .title-2{
    font-size: 12px;
}
.FromTKHSYHCT .ant-form-item .ant-form-item-label >label::after {
    content: "" !important;
}
.FromTKHSYHCT .w-40{
    width: 40%;
    padding-bottom: 10px;
    border: 1px groove #0067ac;
    padding: 5px;
    background: #eaf2ff;
 
}
.FromTKHSYHCT .form-control.input-select{
    border: 0px !important;
    padding: 1px 0px !important;
}
.FromTKHSYHCT .ant-select-single {
    font-size: 12px;
    height: 28px;
}
.FromTKHSYHCT .ant-select-arrow {
    top: 50% !important;
}
.FromTKHSYHCT .formrow{
    height: 30px;
}
.FromTKHSYHCT .tableICDKT{
    margin-top: 5px;
}
.FromTKHSYHCT .lablename-tkhs{
    font-size: 12px;
    margin-top: 6px;
    width: 70px;
}

.FromTKHSYHCT .w-60{
    width: 59.5% !important;
    padding-left: 4px;
    border: 1px groove #0067ac;
    padding: 5px;
    margin-left: 2px;
    background: #eaf2ff;

}
.FromTKHSYHCT .iteminput{
    height: 25px;
}
.FromTKHSYHCT .formrow1{
    height: 28px;
}
.FromTKHSYHCT .tinhtrangnbrv{
    margin-top: 10px;
}
.FromTKHSYHCT .lablename-tkhs-c{
    font-size: 12px;
    margin-top: 6px;
}
.FormLichSuCLSThuoc .ant-tabs-tab {
    color: #000 !important;
    border-radius: 0px !important;
    border: 2px solid #ebebeb !important;
}
.FormLichSuCLSThuoc .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #c7c7c7 !important;
}
.FormLichSuDVKT{
    width: 100%;
    display: inline-block;
}
.FormLichSuDVKT .scroll {
    width: auto;
    height:80vh;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;

  }
  .FormLichSuDVKT  .tableDVKT{
    display: block;
    width: 175%;
  }

  .FormLichSuDVKT .name{
    width:30%;
  }
  .FormLichSuDVKT .sl{
    width: 10%;
  }
  .FormLichSuDVKT .dongia{
    width: 20%;
  }
  .FormLichSuDVKT .tongtien{
    width: 20%;
  }
  .FormLichSuDVKT .ngaycdinh{
    width: 20%;
  }
  .FormLichSuDVKT .phthuchien{
    width: 25%;
  }
  .FormLichSuDVKT .nguoicdinh{
    width: 20%;
  }
  .FormLichSuDVKT .trangthai{
    width: 10%;
  }
  .FormLichSuDVKT .thuchien{
    width: 10%;
  }
  .FormLichSuDVKT .nhom{
    width: 10%;
  }
  .KhamBenhYHCTTab .btnthuoc{
    display: flex;
    margin-bottom: 10px;
  }
