.sieuam-form-input-label {
    width: 80px;
}

.indexSieuAm .check {
    padding-right: 20px;
}

.indexSieuAm .ant-select-single {
    font-size: 14px;
    height: 20px;
}

.indexSieuAm .ant-select-arrow {
    top: 50% !important;
}

.indexSieuAm .row-height-tex {
    height: 48px;
}

.indexSieuAm .row-height-1 {
    height: 33px;
}

.indexSieuAm .ant-form-item .ant-form-item-label>label {
    height: auto !important;
    margin-top: 8px;
}

/*=======================================*/
.CLSSieuAm-main {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    padding-left: 5px;
}

.CLSSieuAm-main .CLSSieuAm-left {
    float: left;
    width: 30%;
    background: #eaf2ff;
    padding: 5px 5px;
}

.CLSSieuAm-main .CLSSieuAm-right {
    float: left;
    width: 70%;
    background: #eaf2ff;
    padding: 5px 10px;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin {
    display: inline-block;
    width: 100%;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .img {
    float: left;
    width: 15%;
    padding-right: 10px;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile {
    float: left;
    width: 85%;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile .item-tt {
    display: flex;
    margin-bottom: 5px;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile .item-tt .namel {
    font-size: 12px;
    width: 65px;
    text-align: right;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile .item-tt .item-cot {
    display: flex;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile .item-tt .namell {
    font-size: 12px;
    width: 100px;
    text-align: right;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile .item-tt .nameNN {
    font-size: 12px;
    width: 65px;
    text-align: right;
}

.CLSSieuAm-main .CLSSieuAm-left .thongtin .profile .item-tt .doituong {
    font-weight: 700;
    font-size: 12px;
    color: #ff0000;
    margin-left: 10px;
}

.CLSSieuAm-main .CLSSieuAm-left .row-item {
    display: flex;
    margin-bottom: 5px;
}

.CLSSieuAm-main .CLSSieuAm-left .row-item span {
    font-size: 12px;
    text-align: right;
    width: 80px;
}

.CLSSieuAm-main .CLSSieuAm-left .row-item span.st {
    width: 170px;
}

.CLSSieuAm-main .CLSSieuAm-left .row-item span.g {
    width: 10px;
}

.CLSSieuAm-main .CLSSieuAm-left .title {
    font-weight: 700;
    background: #f0f0f0;
    padding: 5px 10px;
}

.CLSSieuAm-main .CLSSieuAm-left .camera video {
    height: 380px;
    background: #f0f0f0;
    width: 100%;
    border: 1px groove;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop {
    display: inline-block;
    width: 100%;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left {
    float: left;
    width: 70%;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-right {
    float: left;
    width: 30%;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR .colItem {
 
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR .colItem .colItem-name {
    float: left;
    width: 35%;
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR .colItem .txtInput {
    float: left;
    width: 65%;
    height: 25px;
    line-height: 20px;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR .colItem .ant-select-arrow {
    top: 50% !important;
}

.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR .dbbenh {
    float: left;
    width: 11%;
    font-size: 12px;
    text-align: right;
}
.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR{
    margin-top:2px;
    display: inline-block;
    width: 100%;
}
.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-left .itemR .txtInput1 {
    float: left;
    width: 89%;
}
.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-right .rowItemR  {
    margin-top:2px;
    display: inline-block;
    width: 100%;
}
.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-right .rowItemR  .colname{
    font-size: 12px;
    text-align: right;
    float: left;
    width: 25%;
    margin-top: 3px;
}
.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-right .rowItemR  .txtInputR{
    float: left;
    width: 75%;
    height: 22px;
    line-height: 20px;
}
.CLSSieuAm-main .CLSSieuAm-right .actiontop .actiontop-right .rowItemR .ant-select-arrow {
    top: 50% !important;
}
.CLSSieuAm-main .CLSSieuAm-right .tabs-title {
    font-size: 12px;
    }