.goi-dvkt-vien-phi .nhomin-act-check{ display: flex;}
.goi-dvkt-vien-phi .title-goi-dvkt{ display: flex; margin-bottom: 5px; }
.DetailGoiKhamVP label{
    margin-top: 3px;
}
.DetailGoiKhamVP label.ant-checkbox-wrapper{ margin-top: 0px; }
.DetailCTGoiKham .ant-select-arrow {
    top: 50% !important;
}
.DetailCTGoiKham label {
    margin-top: 3px;
}
.DetailGoiKhamVP .ant-input-number .ant-input-number-input {
    padding: 3px 11px 0px 11px !important;
}