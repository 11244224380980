.ant-modal.ToaMuaNgoai{
    max-width: 100% !important;
}

.ToaMuaNgoai .ant-modal-header{ 
    background: #e8e8e8 !important;
    padding: 5px 10px;
    height: auto !important;
}
.ToaMuaNgoai  .ant-modal-title{
    width: 100%;
}
.ToaMuaNgoai  .btn-header{
    float: right;
}
.ToaMuaNgoai  .title-header{
    float: left;
}
.ToaMuaNgoai .checkthuoc{
    margin-top: 10px;
}
.ToaMuaNgoai .ant-form-item .ant-form-item-label >label::after {
   margin-top: 8px;
}
.ToaMuaNgoai .checkthuocdv{
    padding-right: 20px;
}
.ToaMuaNgoai .txtname{
    margin-top: 10px;
}
