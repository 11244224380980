.notfound{
   /* background: url("../../assets/image/bglogin.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;*/
    background: #f0f0f0;
    height: 100vh;
}
.notfound h1{
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    font-weight: 800;
    padding-top: 300px;
}
.trangchu{
    text-align: center;
    color: #0067ac;
}
.loading{
    margin-left: 100px;
}