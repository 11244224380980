
//row
.vienphi-form-row {
    height: 35px !important;
}
.vienphi-form-row-last {
    height: 30px !important;
}
.DetailDVKT .row > * {
    padding-right: 4px !important;
    padding-left:  4px !important;
}
.DetailDVKT  .dmvp-1 .lablename-input,.DetailDVKT  .dmvp-1 .lablename {
    width: 120px;
}
.DetailDVKT .dmvp-1 .lablename-9324_A {
    width: 120px;
    margin-top: 5px;
}
.DetailDVKT .cdvltl {
    width: 200px;
}
.DetailDVKT .pa {
   padding-left: 20px !important;
}
.ant-modal-header{ background: #0067ac !important;}
.DM-DVKT .actionright{
    text-align: right;
}

.DetailDVKT .ant-select .ant-select-selector {
    border-radius: 0px !important;
    font-size: 12px;
    text-align: left;
}
.DetailDVKT .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.DetailDVKT .ant-select-arrow {
    top: 50% !important;
}
.DetailDVKT .lablename-s{
    margin-top: 5px;
    width: 120px;
}

.DetailDVKT  .ant-input-number .ant-input-number-input {
    padding: 5px 11px !important;
}

.NhomKhoaPhong span::before {
    display: inline-block;
    margin-inline-end: 4px;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: ",";
}
.NhomKhoaPhong span:nth-child(1)::before {
    display: inline-block;
    margin-inline-end: 4px;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "";
}
.datagrid-body-inner{
    display: inline-block;
  }
  .tableListDVKT .datagrid-field-td:nth-child(4), .tableListDVKT .datagrid-filter-row td:nth-child(4){
    position: sticky;
    left: 0;
    background: #f5f5f5;
    z-index: 1;
}
.tableListDVKT .datagrid-td:nth-child(4){
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 1;
}
.tableListDVKT .datagrid-row-over .datagrid-td:nth-child(4){
    position: sticky;
    left: 0;
    background: #eaf2ff;
    z-index: 1;
}

.combobox-item-selected {
    background-color: #0067ac;
    color: #fff;
}
.combobox-item, .combobox-group, .combobox-stick {
    font-size: 12px;
    padding: 3px 4px;
    line-height: 20px;
}
.textbox .textbox-text {
    font-size: 12px;
}

.datagrid-htable .datagrid-cell {
    margin: 0;
    padding: 10px 4px;
    white-space: normal;
    word-wrap: normal !important;
    height: 45px;
    line-height: 15px;
    font-size: 12px;
}
.w-8 {
    width: 8% !important;
}
