.StyleNhomPhanLoaiThuoc .nhomin-act-check {
    display: flex
;
}
.StyleNhomPhanLoaiThuoc .nhomin-act-check .col-n {
    padding-left: 20px;
}
.PhanLoaiDuoc .dmdot .ant-select-selector {
    margin: 0px 0px !important;
    height: 30px !important;
}
.PhanLoaiDuoc .ant-select-arrow {
    top: 50% !important;
}
.PhanLoaiDuoc .ant-select-single .ant-select-selector .ant-select-selection-item, .PhanLoaiDuoc .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    text-align: left;
}