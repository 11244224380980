.DSNhanVienCLS .headerDS{
    display: flex;
    padding: 10px;
}
.DSNhanVienCLS .headerDS .vienphi-danhmuc-title {
    width: 25%;
    margin-top: 5px;
}
.DSNhanVienCLS .headerDS .actiondsnhanvien {
    width: 75%;
}
.DSNhanVienCLS .form-control.input-select{
    border: 0px !important;
    padding: 1px 0px !important;
}
.DSNhanVienCLS .ant-form-item .ant-form-item-label >label::after {
    content: "" !important;
}
.DSNhanVienCLS .ant-select-single {
    font-size: 12px;
    height: 28px;
}
.DSNhanVienCLS .ant-select-arrow {
    top: 50% !important;
}
.DSNhanVienCLS .ant-form-item-control-input {
    min-height: 30px !important;
}
.DSNhanVienCLS  .name-dsnhanvien{
    font-size: 12px;
}
.DSNhanVienCLS  button{
    float: left;
    margin-right: 5px;
}