.hopdonggoikham .color-border {
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
}

.hopdonggoikham .color-border.p-1 {
    padding-bottom: 20px !important;
}

.hopdonggoikham .form-input-label.namehd {
    margin-top: 8px;
}

.hopdonggoikham .ant-form-item .ant-form-item-label>label::after {
    margin-top: 8px;
}

.hopdonggoikham .form-btn {
    padding: 10px 5px !important;

}

.hopdonggoikham .md2 {
    width: 15% !important;
}

.title-timhd {
    font-size: 14px;
    font-weight: 800;
    padding: 6px 0px 0px 10px;
    color: #fff;
}

.timhopdong .form-input-label.namehd {
    margin-top: 8px;
}

.timhopdong .ant-form-item .ant-form-item-label>label::after {
    margin-top: 8px;
}

.themmoicty .namethemmoicty {
    width: 75px;
}

.themmoicty .ant-form-item-control-input {
    min-height: 30px !important;
}

.intraketquahsksk .namein {
    margin-top: 8px;
}

.intraketquahsksk .ant-form-item .ant-form-item-label>label::after {
    margin-top: 8px;
}

.khaibaogoikhamsk {
    display: flex;
}

.khaibaogoikhamsk .khaibaogoikhamsk-left {
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px groove #0067ac;
    padding: 5px;
}

.khaibaogoikhamsk .khaibaogoikhamsk-right {
    width: 59.5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px groove #0067ac;
    padding: 5px;
    margin-left: 3px;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk {
    display: flex;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .title {
    font-weight: 800;
    width: 35%;
    font-size: 13px;
    padding: 5px;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .btn-danhsachgoi {
    width: 65%;
    margin: 0px;
    padding: 0px;
    list-style: none;
    justify-content: end;
    align-items: end;
    float: right;
    text-align: right;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .btn-danhsachgoi li {
    float: left;
    padding-right: 10px;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .btn-danhsachgoi li.checkanhien {
    display: flex;
    margin-top: 3px;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .btn-danhsachgoi li.checkanhien div {
    margin-right: 5px;
    font-size: 12px;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .btn-danhsachgoi li.checkanhien div.dsan {
    margin-top: 2px;
}

.khaibaogoikhamsk .top-khaibaogoikhamsk .btn-danhsachgoi .ant-btn {
    height: 25px !important;
    padding: 4px 10px !important;
}

.tablegoikham .headertable {
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style: none;
    background: #ccc;
}

.tablegoikham .headertable li {
    float: left;
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    padding: 5px;
    font-size: 12px;
}

.tablegoikham .headertable li:nth-child(1) {
    border-left: 1px solid #e3e3e3;
    width: 96px;
}

.tablegoikham .headertable li:nth-child(2) {
    width: 253px;
}

.tablegoikham .headertable li:nth-child(3) {
    width: 53px;
    text-align: center;
}

.tablegoikham .headertable li:nth-child(4) {
    width: 62px;
    text-align: center;
}
.KhaiBaoGoiKhamSK .title-khaibao{
    font-weight: 800;
    font-size: 13px;
    background:#0067ac;
    padding:7px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.tablegoikham .headertablechitiet {
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style: none;
    background: #ccc;
}

.tablegoikham .headertablechitiet li {
    float: left;
    border-top: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    padding: 5px;
    font-size: 12px;
}

.tablegoikham .headertablechitiet li:nth-child(1) {
    border-left: 1px solid #e3e3e3;
    width: 384px;
}

.tablegoikham .headertablechitiet li:nth-child(2) {
    width: 50px;
}

.tablegoikham .headertablechitiet li:nth-child(3) {
    width: 200px;
    text-align: center;
}

.tablegoikham .headertablechitiet li:nth-child(4) {
    width: 62px;
    text-align: center;
}