.sieuam-form-input-label {
    width: 80px;
}
.indexNoiSoi  .check{
    padding-right: 20px;
}

.indexNoiSoi .ant-select-single {
    font-size: 14px;
    height: 20px;
}
.indexNoiSoi .ant-select-arrow {
    top: 50% !important;
}
.indexNoiSoi .row-height-tex{
    height: 48px;
}
.indexNoiSoi .row-height-1{
    height: 33px;
}
.indexNoiSoi .ant-form-item .ant-form-item-label >label{
    height: auto !important;
    margin-top: 8px;
}

