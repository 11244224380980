.Crop-Controls{
    display: flex;
    margin-bottom: 10px;
}
.Crop-Controls .full{
    padding-right: 10px;
}
.Crop-Controls .xoay{
    padding-right: 10px;
}
.Crop-Controls input{
    border: 1px solid #ccc;
}

.Crop-Controls .congcu button{
    border: 1px solid #ccc;
}
.CropImage .hinhanh{
    margin-right: 10px;
}
.CropImage .rowimage{
    display: flex;
}
.btncrop {
    margin-top: 10px;
    text-align: right;
}
.btncrop button{
    margin-left: 5px;
    border: 1px solid #ccc;
}
