//title
.tiepnhan-congkham-title {
    background-color: #0067ac;
    color: white;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 13px;
}

//icon
.tiepcan-congkham-icon {
    display: flex;

    div {
        border: 1px solid black;
        margin: 0px 1px;
        padding: 2px;
        width: 24px;
    }
}

//row
.tiepnhan-congkham-row {
    height: 22px !important;
}

//input
.form-control-select {
    font-size: 11px !important;
    padding: 0 !important;
    border-radius: none !important;
}

// lable input
.tiepnhan-congkham-form-input-label {
    font-size: 12px;
    width: 80px;
}

.tiepnhan-congkham-form-input-label-right {
    font-size: 12px;
    width: 59px;
}

.tiepnhan-congkham-form-input-label-right-form-last {
    font-size: 12px;
    width: 80px;
}

//select
.tiepnhan-congkham .ant-select-selector {
    height: 26px !important;
    margin: 3px 0px 0px 0px !important;
}

.tiepnhan-congkham .thongtindangky-2 .ant-select-selector {
    height: 26px !important;
    margin: 0px 0px 0px 0px !important;
}

.tiepnhan-congkham .thongtindangky-2 .ant-form-item-control-input {
    min-height: 30px !important;
}

.tiepnhan-congkham .thongtindangky-2 .form-row {
    height: 25px !important;
}

.tiepnhan-congkham .thongtindangky-2 .ant-select-arrow {
    top: 50% !important;
}

.tiepnhan-congkham .thongtindangky-2 {
    margin-top: 8px;
}

.tiepnhan-tt .thongtindangky-2 label {
    margin-top: 0px;
}

.tiepnhan-congkham .ant-select-arrow {
    top: 55% !important;
}

input[type="search"] {
    height: 25px !important;
    border-radius: 0px !important;
}

.input-select .ant-select .ant-select-selector {
    border-radius: 0px !important;
}

//
.tiepnhan-congkham-border {
    border-left: 1px solid rgb(182, 182, 182);
}

//
.tiepnhan-congkham-table-ttkdk {
    height: 20px;
}

.row-fix label {
    margin-top: 3px;
}

.row-fix label::after {
    content: "" !important;
}

.tiepnhan-hc label {
    margin-top: 4px;
}

.tiepnhan-hc .w-75,
.row-fix .date-tiepnhan-bh {
    height: 26px;
}

.tiepnhan-congkham input[type="date"] {
    line-height: 20px !important;
}

.tiepnhan-tt label {
    margin-top: 4px;
}

.tiepnhan-tt .ksk {
    width: 120px;
}

.tiepnhan-congkham-border .tt-bhyt {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.tiepnhan-congkham .row>* {
    padding: 0px 5px !important;
    // margin: 0px 5px !important;
}

.tiepnhan-congkham-form-input-label.gioitinh {
    width: 35px;
}

.mr-tn {
    margin-left: 15px;
}

.tiepnhan-congkham .row>.gggggg {
    padding-right: 15px !important;
}

.tiepnhan-congkham .ant-select-selector {
    font-size: 12px;
}

.headertop {
    height: 35px;
}

.btn-tiepnhan {
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 1;
    width: 40%;
    background: #e9f2ff;
    padding-bottom: 2px;
}

.btn-tiepnhan .vienphi-danhmuc-btn {
    border-radius: 0px !important;
    padding: 2px 5px !important;
    margin: 0px 2px;
}

.tiepnhan-table-dskham .panel-header,
.tiepnhan-table-dskham .panel-body {
    //  border: 1px solid #ff0000;
}

.dskhambenh .tiepnhan-title {
    border-radius: 0px;
}

.dskhambenh .datagrid-row:hover {
    background: #eaf2ff;
    cursor: pointer;
}

.dskhambenh .datagrid-htable .datagrid-cell {
    margin: 0;
    padding: 10px 4px;
    white-space: normal;
    word-wrap: normal !important;
    height: 30px;
    line-height: 15px;
    font-size: 12px;
}

.tiepnhan-congkham .form-control:disabled {
    background-color: #f5f5f5 !important;
    opacity: 1;
}

.ngaysinh-tuoi {
    display: flex;
}

.ngaysinh-tuoi .ngaysinhtiepnhan {
    width: 87%;
}

.ngaysinh-tuoi .tuoitiepnhan {
    width: 13%;
}

.huyetap-tiepnhan {
    display: flex;
}

.huyetap-tiepnhan .huyetap-1 {
    width: 70%;
}

.huyetap-tiepnhan .huyetap-2 {

    width: 30%;
}

.tiepnhan-congkham .searchkhoa {
    position: relative;
}

.tiepnhan-congkham .searchkhoa .optiontablehide {
    display: none;
}

.tiepnhan-congkham .searchkhoa .optiontableshow {
    display: block;
    position: absolute;
    top: 28px;
    left: 0px;
    z-index: 10;
    background: #fff;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}
.optiontableshow .scrolltable-ck{
    position: relative; overflow: hidden; 
    overflow-y: scroll;overflow-x: scroll; height: 200px; width: 660px;
}
.optiontableshow .scrolltable-ck input{
    height: 15px !important;
}
/*
.optiontableshow .table thead,
.optiontableshow .table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.optiontableshow .table tbody {
    display: block;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: auto;
}*/

.optiontableshow .table {
    width: 100%;
    min-width: 630px;
    font-size: 12px;
}

.optiontableshow .table thead tr {
    background: #f0f0f0;
}

.optiontableshow .table tbody tr {
    cursor: pointer;

}

.optiontableshow .table tbody tr td,
.optiontableshow .table thead tr th {
    padding: 2px 10px;
}

.optiontableshow .table tbody tr:hover {
    background: #f0f0f0;
    color: #000;
}

.optiontableshow .table thead tr .doituong,
.optiontableshow .table tbody tr .doituong {
    width: 15%;
    text-transform: capitalize;
}

.optiontableshow .table thead tr .tendvkt,
.optiontableshow .table tbody tr .tendvkt {
    width: 55%;
}

.optiontableshow .table thead tr .dongia,
.optiontableshow .table tbody tr .dongia {
    width: 15%;
}
.optiontableshow .table thead tr .goikham,
.optiontableshow .table tbody tr .goikham {
    width: 15%;
    text-align: center;
}

.table-chidinh .datagrid-body-inner {
    display: inline-block;
}

.btn-congkham {
    margin: 2px 0px;
}

.btn-congkham .bnthuy {
    font-size: 12px;
    padding: 5px;
    border-radius: 0px;
}

.table-chidinh .checkbox {
    position: relative;
    border: 2px solid #ed004a;
    border-radius: 5px 5px 5px 5px;
}

.table-chidinh .checkbox-checked {
    border: 0;
    background: #ed004a;
}

.tiepnhan-congkham .congkham {
    display: flex;
}

.tiepnhan-congkham .congkham .congkham-name {
    font-size: 12px;
    margin-top: 2px;
    width: 12%;
}

.tiepnhan-congkham .congkham .congkham-search {
    width: 88%;
}

.tiepnhan-congkham .datagrid-row-selected {
    background: #E0ECFF;
}

.btn-congkham {
    display: flex;
    float: right;
}

.btn-congkham .check-giadv {
    margin-right: 10px;
    display: flex;
}

.tiepnhan-tt .btn-congkham .check-giadv label {
    margin-top: 0px;
}

.tiepnhan-tt .btn-congkham .check-giadv .ant-form-item-control-input {
    min-height: 32px !important;
}

.btn-check-congkham {
    display: inline-block;
    width: 100%;
}

.ant-table-cell-row-hover {
    background: none !important;
    transition: 0s !important;
    padding: 0px;
}

.doi-tuong-kham-benh .absolute {
    position: absolute;
    z-index: 99;
    right: 0;
    width: 600px;
    background: #fff;
}

.w-full {
    width: 100%;
    border: 1px solid 1 #d9d9d9 !important;
    border-radius: 0px;
}

.doi-tuong-kham-benh thead>tr {
    position: relative;
    display: flex;
    background: #f0f0f0;
}

.doi-tuong-kham-benh thead tr td.ma {
    width: 60px;
    max-width: 60px;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.doi-tuong-kham-benh thead>tr td.trichdan {
    width: 350px;
    max-width: 350px;
    border-right: 1px solid #ccc;
}

.doi-tuong-kham-benh thead>tr td.diengiai {
    width: 350px;
    max-width: 350px;
    border-right: 1px solid #ccc;
}



.doi-tuong-kham-benh tbody td.ma {
    width: 57px;
    max-width: 60px;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.doi-tuong-kham-benh tbody td.trichdan {
    width: 370px;
    border-right: 1px solid #ccc;
}

.doi-tuong-kham-benh thead>tr td.diengiai,
.doi-tuong-kham-benh tbody td.diengiai {
    width: 350px;
    max-width: 350px;
    border-right: 1px solid #ccc;
}

.doi-tuong-kham-benh .table-responsive {
    border-collapse: collapse;
    width: 100%;
    display: block;
}

.doi-tuong-kham-benh .table-responsive thead {
    display: inline-block;
    width: 100%;
}

.doi-tuong-kham-benh .table-responsive tbody {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 140px;
}
.doi-tuong-kham-benh .table-responsive tbody tr {
   cursor: pointer;
}
.doi-tuong-kham-benh .table-responsive tbody tr:hover {
    background: #f0f0f0;
 }

.doi-tuong-kham-benh .table-responsive thead {
    position: sticky;
    top: 0;
}

.doi-tuong-kham-benh .input-icon {
    position: relative;
}

.doi-tuong-kham-benh table tr td {
    padding: 3px;
    font-size: 12px;
}

.doi-tuong-kham-benh .input-icon .w-full::placeholder {
    font-size: 12px;
}
.doi-tuong-kham-benh .input-icon .w-full {
    font-size: 12px;
}

.doi-tuong-kham-benh .iconsearch{
    position: absolute;
    right: 10px;
    top: 5px;
    color: rgba(0, 0, 0, 0.25);
}
.doi-tuong-kham-benh .iconsearchxoa{
    cursor: pointer;
}
.btn-congkham  .checkgiadv{
    margin-top: 10px;
    margin-right: 15px;
    border: 1px solid #ff0000 !important;
    height: 15px !important;
    width: 15px !important;
}
.btn-congkham  .check-giadv span{
    font-size: 12px;
    margin-right: 6px;
    margin-top: 8px;
} 
.swal2-confirm {
    background-color: #0067ac !important;
}
.swal2-cancel{
    background-color: #ed004a !important;
}
button:hover {
    color: #ccc !important;
}
.swal2-warning {
    border-color: #0067ac !important;
    color: #0067ac !important;
}
.ant-btn-primary {
    color: #fff;
    background: #0067ac;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-primary:hover {
    color: #fff;
    background: #ed004a !important;
}
.ant-modal-confirm-btns .ant-btn-default {
    background: #ed004a;
    border-color: #ed004a;
    color:#fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.ant-modal-confirm-btns .ant-btn-default:hover {
    border-color: #ed004a !important;
    color:#000 !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
    color: #ed004a;
}
.onclickimg{
    cursor: pointer;
}
.print-congkham{
    display: none;
}
.phong-chi-dinh{
    width: 100%;
}
.tiepnhan-congkham .ant-picker-input input{
    font-size: 12px !important;
    height: 22px !important;
}
.batbuoc{
    color: #ed004a;
}
.css-xwn1oi-MuiFormControl-root-MuiTextField-root{
    height: 26px;
}
.css-1n04w30-MuiInputBase-root-MuiOutlinedInput-root{
    height: 26px !important;
    line-height: 0px !important;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input{
    height: 26px !important;
    padding: 2px !important;
    font-size: 12px !important;
}
.css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root{
    line-height: auto !important;
    top: 0px !important;
    font-size: 10px !important;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
   border-radius: 0px !important;
   height: 26px;
   border: 1px solid #d9d9d9 !important;
   top: 0px !important;
}
.css-1ysp02-MuiButtonBase-root-MuiIconButton-root{
    padding: 0px !important;
}
.css-1umw9bq-MuiSvgIcon-root {
    width: 0.5em !important;
    height: 0.5em !important;
    font-size: 1.5rem;
}
.css-113d811-MuiFormLabel-root-MuiInputLabel-root{
    display: none !important;
}
.ant-form legend {
    display: none;
    width: 100%;
    margin-bottom:0px !important;
    padding: 0;
    color:  #ff0000 ;
    font-size: 12px !important;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
}
.css-elo8k2-MuiInputAdornment-root{
    margin-left: 0px !important;
}
input.MuiInputBase-input::placeholder {
    display: none;
  }
  .table-chidinh .datagrid-row-selected{
    background: none !important;
  }